.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Sticky navbar... */
.bi {
    vertical-align: -.125em;
    fill: currentColor;
}

.nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
}

.nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}


/* main > .container {
    padding: 60px 15px 0;
} */


.pt-3-navbar-spacer {
    padding-top: calc(60px + 1rem);
}

.fixed-top-navbar-min-height {
    min-height: 60px;
}


/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .custom-nomargin-modal>.modal-dialog>.modal-content {
        min-width: 500px !important;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}

/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .custom-nomargin-modal>.modal-dialog {
        width: inherit !important;
        margin-left:auto !important;
        margin-right:auto !important;
    }
    .custom-nomargin-modal>.modal-dialog>.modal-content {
        
    }
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {}

/* // X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {}