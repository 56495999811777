.header-table {
    padding: 0.5rem 0 0.5rem 0;
    border: 0px solid #cbccce;
}

.table-music {
    padding: 0.5rem 0 0.5rem 0;
    border: 0px solid #cbccce;
    border-width: 0px;
    border-top-width: 0px;
}

.table-music:hover:nth-child(1n) {
    background-color: #d1d2d4;
}

.table-music:nth-child(2n) {
    background-color: #e2e3e5;
}

.table-music:nth-child(2n+1) {
    background-color: #d7d8da;
}

.col-table {
    border: 0px solid #000;
}

.custom-tooltip>.tooltip-inner{
    max-width: fit-content;
}